<template>
  <div class="faq-detail-section">
    <!-- START Header -->
    <header-faq @searchValue="getSearchedValue" />
    <!-- END Header -->
    <div id="content">
      <!-- Carousel -->
      <div
        v-if="!searched"
        class="wrapper-carousel"
      >
        <div class="head-title-carousel">
          {{ carouselHeadTitle }}
        </div>
        <div class="carousel">
          <carousel
            slider-dot-position="center"
            :show-dot="false"
            :name="carouselName"
            :shimmer="false"
            :total-all-data="10"
            :size="categories.length"
          >
            <template #item>
              <div
                v-for="(slides, index) in categories"
                :id="`${carouselName}-${index+1}`"
                :key="'categories-box-'+ index"
              >
                <template>
                  <div
                    v-for="(perTopic, idx) in slides"
                    :id="`id-${carouselName}-${index}-${idx}`"
                    :key="'topic-' + idx"
                    class="carousel-card"
                    @click="goToDetail($route.params.category.toLowerCase(), perTopic.alias)"
                  >
                    <Cards
                      :title="perTopic.alias.replace(/-/g, ' ')"
                      :src="getIcon($route.params.category.toUpperCase(), perTopic.name)"
                      :bg-color="getColor($route.params.category.toUpperCase(), perTopic.name)"
                      :category="$route.params.category.toUpperCase()"
                      :topic="perTopic.name"
                    />
                  </div>
                </template>
              </div>
            </template>
          </carousel>
        </div>
      </div>
      <!-- Carousel -->
      <!-- Collapse -->
      <div
        v-if="!searched"
        class="wrapper-collapse"
      >
        <div class="coat-title-collapse">
          <div
            class="circle-icon"
            :style="{ backgroundColor: iconBgColor }"
          >
            <img
              :src="getImg()"
              class="icon-title"
              alt="icon.png"
            >
          </div>
          <div class="head-title-collapse">
            <span
              style="margin: auto; text-transform: capitalize;"
            >{{
              $route.params.topic.replace(/-/g, ' ')
            }}</span>
          </div>
        </div>
        <div
          v-if="!isLoadingToFetch"
          class="wrapper-issues"
        >
          <div
            v-for="(detail, index) in listDetail"
            id="issues"
            :key="detail.faq_id"
          >
            <CardCollapse
              :index="index"
              :sub-title="detail.question"
              :description="detail.answer"
              is-expand
              expand-disappear
              class="item-collapse"
            />
          </div>
        </div>
        <div class="content-chevron">
          <input-pagination
            v-model="page"
            :total-all-item="total_all_data"
            :per-page="perPage"
            :disable="false"
            @next="incrementPage"
            @prev="decrementPage"
            @changeData="changePage"
          />
        </div>
      </div>
      <!-- Collapse -->
      <!-- Search Result -->
      <div
        v-if="searched"
        class="wrapper-collapse"
      >
        <div class="pResult">
          <p>Search Result: {{ total_all_dataRes }} Question Related to “{{ keyword }}”</p>
        </div>
        <div
          v-if="!isLoadingToFetch && listResSearched.length>0"
          class="wrapper-result"
        >
          <div
            v-for="(result, index) in listResSearched"
            id="result"
            :key="result.faq_id"
          >
            <CardCollapse
              :index="index"
              :sub-title="highlight(result.question)"
              :description="result.answer"
              is-expand
              expand-disappear
              class="item-collapse"
            />
          </div>
          <div class="content-chevron">
            <input-pagination
              v-model="pageRes"
              :total-all-item="total_all_dataRes"
              :per-page="perPageRes"
              :disable="false"
              @next="incrementPageRes"
              @prev="decrementPageRes"
              @changeData="changePageRes"
            />
          </div>
        </div>
        <NotFound v-if="listResSearched.length === 0" />
      </div>
      <!-- Search Result -->
    </div>
  </div>
</template>

<script>
import HeaderFaq from '../components/Header';
import mixinMobile from '../../../misc/mixinMobile';
import mixinMetaInfo from '../../../misc/mixinMetaInfo';
import Cards from '../components/Cards';
import FAQ_CONTENT from '../app/constants/content';
import Carousel from '@/components/new-carousel/Carousel';
import { chunkArray } from '@/components/new-carousel/util';
import FAQ from '../app/usecase/faq.js';
import CardCollapse from '@/components/new-card-collapse';
import DATA_STYLE from '../app/constants/getIcon&Color';
import NotFound from '../components/NotFound';
import inputPagination from '@/components/new-pagination/Pagination.vue';

export default {
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  name: 'FAQ',
  components: {
    HeaderFaq,
    Cards,
    Carousel,
    CardCollapse,
    NotFound,
    inputPagination,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_faq_detail', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      carouselName: 'category',
      DATA_STYLE,
      categoryTopic: [],
      isLoadingFetchData: false,
      page: 1,
      perPage: 5,
      ofPages: null,
      total_all_data: 1,
      carouselHeadTitle: '',
      icon: '',
      iconBgColor: '',
      searched: false,
      keyword: '',
      resSearched: [],
      pageRes: 1,
      perPageRes: 5,
      ofPagesRes: null,
      total_all_dataRes: null,
    };
  },
  computed: {
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
    listDetail() {
      const data = this.categoryTopic;
      return data;
    },
    listResSearched() {
      return this.resSearched;
    },
    categories() {
      const perChunk = 5;
      const contentData = [];
      const cat = this.$route.params.category.toUpperCase();
      const firstTopic = this.$route.params.topic;
      const newSource = FAQ_CONTENT.find(v => v.label === cat);

      if (newSource) {
        for (const val of newSource.value) {
          contentData.push(val);
        }
      }
      const [newPickData, newDelData] = [[], []];

      contentData.forEach((item) => {
        if (item.alias === firstTopic) {
          newPickData.push(item);
        }
        if (item.alias !== firstTopic) {
          newDelData.push(item);
        }
      });

      for (const valPick of newPickData) {
        newDelData.unshift(valPick);
      }
      const finalData = newDelData;


      if (this.windowWidth <= 766) {
        return chunkArray(finalData.slice(0, 9), 1);
      }
      return chunkArray(finalData, perChunk);
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.page = Number(this.$route.query.page);
        this.fetchDetailList(this.page);
      },
      deep: true,
    },
  },
  created() {
    if (!this.isSsr()) {
      window.scrollTo(0, 1);
    }
    if (this.$route.params.category.toUpperCase() === 'BANTUAN') {
      this.carouselHeadTitle = 'Bantuan untuk Anda';
    } else this.carouselHeadTitle = 'Bantuan terkait Aplikasi';

    if (this.$route.query.search) {
      this.getSearchedValue({ pageRes: this.$route.query.page, keyword: this.$route.query.search, searched: true });
    }
  },
  mounted() {
    this.page = Number(this.$route.query.page);
    this.fetchDetailList(this.page);
  },
  methods: {
    highlight(words) {
      const regex = new RegExp(this.keyword, 'gi');
      return words.replace(regex, "<mark style='color:#D11F40; background-color:#FEE8E8'>$&</mark>");
    },
    getSearchedValue(value) {
      this.pageRes = (value.pageRes) ? Number(value.pageRes) : 1;
      if (value.keyword) {
        this.fetchSearchResult(value.keyword, this.pageRes);
        this.keyword = value.keyword;
      }
      this.searched = value.searched;
      this.page = 1;
      this.ofPages = null;
      this.ofPagesRes = null;
      this.fetchDetailList(this.page);
    },
    getImg() {
      const cat = this.$route.params.category.toUpperCase();
      const top = FAQ.getAlias(this.$route.params.topic.toLowerCase());
      this.iconBgColor = this.getColor(cat, top);
      return this.getIcon(cat, top);
    },
    goToDetail(category, topic) {
      if (category != this.$route.params.category || topic != this.$route.params.topic) {
        this.$router.push(`/faq/${category}/${topic}?page=1`);
        this.page = 1;
        this.fetchDetailList(this.page);
      }
    },
    async fetchDetailList(page) {
      this.isLoadingFetchData = true;
      const data = await FAQ.getPerCategoryTopic(page, this.perPage, this.$route.params.category, this.$route.params.topic);
      if (data.err === null) {
        this.isLoadingFetchData = false;
        this.categoryTopic = data.data;
        this.total_all_data = Number(data.pagination.total_all_data);
        this.ofPages = Math.ceil(data.pagination.total_all_data / this.perPage);
      } else {
        this.isLoadingFetchData = false;
      }
    },
    incrementPage() {
      this.page = Number(this.page);
      if (this.page === this.ofPages) {
        return false;
      }
      this.page += 1;
      this.fetchDetailList(this.page, this.perPage);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.page}`);
      }
    },
    decrementPage() {
      this.page = Number(this.page);
      if (this.page === 1) {
        return false;
      }
      this.page -= 1;
      this.fetchDetailList(this.page, this.perPage);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.page}`);
      }
    },
    changePage(valueChanged) {
      this.fetchDetailList(valueChanged, this.perPage);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${valueChanged}`);
      }
      this.page = valueChanged;
    },
    async fetchSearchResult(keyword, pageRes) {
      this.isLoadingFetchData = true;
      const data = await FAQ.getSearchedPerCategoryTopic(pageRes, this.perPageRes, this.$route.params.category, FAQ.getAlias(this.$route.params.topic), keyword);
      if (data.err === null) {
        this.isLoadingFetchData = false;
        this.resSearched = data.data;
        this.total_all_dataRes = Number(data.pagination.total_all_data);
        this.ofPagesRes = Math.ceil(data.pagination.total_all_data / this.perPageRes);
      } else {
        this.isLoadingFetchData = false;
      }
    },
    incrementPageRes() {
      this.pageRes = Number(this.pageRes);
      if (this.pageRes === this.ofPagesRes) {
        return false;
      }
      this.pageRes += 1;
      this.fetchSearchResult(this.keyword, this.pageRes);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.pageRes}&search=${this.keyword}`);
      }
    },
    decrementPageRes() {
      this.pageRes = Number(this.pageRes);
      if (this.pageRes === 1) {
        return false;
      }
      this.pageRes -= 1;
      this.fetchSearchResult(this.keyword, this.pageRes);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.pageRes}&search=${this.keyword}`);
      }
    },
    changePageRes(valueChanged) {
      this.fetchSearchResult(this.keyword, valueChanged); if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${valueChanged}&search=${this.keyword}`);
      }
      this.pageRes = valueChanged;
    },
    /**
     *
     * @param category {string}
     * @param topic {string}
     * @return {*}
     */
    getIcon(category, topic) {
      return `/images/${DATA_STYLE.FAQ_DETAIL_CONTENT[category][topic.toLowerCase()].src}`;
    },
    /**
     *
     * @param category {string}
     * @param topic {string}
     * @return {string}
     */
    getColor(category, topic) {
      return DATA_STYLE.FAQ_DETAIL_CONTENT[category][topic.toLowerCase()].color;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
